import { hasRoute } from '@/core/plugins/filter';
import { computed } from 'vue';

const usePermissionConfig = () => {
  return {
    /*
    * 以图搜图
    * 控制：设计中心-以图搜图
    * 跳转路由名称：SearchImageList
    * */
    SEARCH_IMAGE: computed(() => hasRoute('SearchImageList')),
  };
};

export default usePermissionConfig;
