import { ref, computed, nextTick, watch } from 'vue';
import { getDyProductList } from '@/modules/doupinku-manage/api';
import { cloneDeep } from 'lodash-es';
import {
  IGoodsItem,
  ICustomParams,
  IParams,
  ISortParams,
  ISearchKeys,
  IFilterTagsItem,
} from '../views/type.d';
import {
  SORT_WORD_LIST,
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  SEARCH_BASE_SETTING_ENUM,
  SEARCH_SALE_SETTING_ENUM,
} from '@/modules/doupinku-manage/constant';
import { DPK_CUSTOM_DICTIONARY_KEY } from '@/constant/dictionary';
import { usePagination } from './use-pagination';
import { commonfuns } from 'cx-utils';
import { $filters } from '@/core/plugins/filter';
import { useDictionaryStore } from '../dictionary';
import { trackingExposure } from '@/utils/tracking';

/* 支持 列表查询 */
export default () => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const dictionaryStore = useDictionaryStore();
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.SPU_TOTAL_SALES,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: ICustomParams = {
    categoryClass: [], // 品类
    commodityStatus: '', // 商品状态
    storeName: '', // 店铺名称
    spuTotalSales: ['', ''], // 总销量
    spuCouponPrice: ['', ''], // 价格
  };
  const FLAT_DESIGN_CATEGORY = computed(() => dictionaryStore.flatCxDictionary(DPK_CUSTOM_DICTIONARY_KEY.CATEGORY));
  // 商品状态
  const COMMODITY_STATUS_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[DPK_CUSTOM_DICTIONARY_KEY.COMMODITY_STATUS] || []);
  // 店铺
  const STORE_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[DPK_CUSTOM_DICTIONARY_KEY.STORE_NAME] || []);
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const finish = ref(false);
  const goodsList = ref<IGoodsItem[]>([]);
  const formatParams = (row: ICustomParams) => {
    const data:IParams = {
      // ...row,
    };
    // 总销量 spuTotalSales
    if (row.spuTotalSales?.length === 2) {
      [data.spuTotalSalesStart, data.spuTotalSalesEnd] = row.spuTotalSales;
      delete row.spuTotalSales;
    }
    // 价格 spuCouponPrice
    if (row.spuCouponPrice?.length === 2) {
      [data.spuCouponPriceStart, data.spuCouponPriceEnd] = row.spuCouponPrice;
      delete row.spuCouponPrice;
    }
    // 处理品类数据
    if (row.categoryClass?.length) {
      let ids: string[] = [];
      row.categoryClass.forEach((v) => {
        ids.push(...v);
      });
      ids = [...new Set([...ids])];
      const categoryClass1: string[] = [];
      const categoryClass2: string[] = [];
      const categoryClass3: string[] = [];
      ids.forEach((id) => {
        const current = FLAT_DESIGN_CATEGORY.value.find(v => v.value === id);
        if (current) {
          const set = new Set([...current.lastLevelChildIds, ...ids]);
          if (set.size === ids.length) {
            const index = current.pIds.length;
            if (index === 0) {
              categoryClass1.push(id);
            } else if (index === 1) {
              categoryClass2.push(id);
            } else if (index === 2) {
              categoryClass3.push(id);
            }
          }
        }
      });
      data.categoryClass1 = categoryClass1;
      data.categoryClass2 = categoryClass2;
      data.categoryClass3 = categoryClass3;
      delete row.categoryClass;
    }
    const currentParams = {
      ...row,
      ...data,
    };
    return currentParams;
  };
  const getList = async (current: number) => {
    try {
      const res = await getDyProductList({
        ...formatParams(cloneDeep(params.value)),
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });
      const newList = res.data?.list || [];
      goodsList.value.push(...newList);
      trackingExposure(newList, 'spu');
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    goodsList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    sortConfig.value.prop = val;
    sortConfig.value.order = sortConfig.value.order === SORT_TYPE_ENUM.DOWN
      ? SORT_TYPE_ENUM.TOP : SORT_TYPE_ENUM.DOWN;
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getDyProductList({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        goodsList.value.push(...list);
        trackingExposure(list, 'spu');
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !goodsList.value.length && !listLoading.value;
  });
  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.CATEGORY: {
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          const text = value.map((item: string[]) => {
            const cates = item.map((id) => {
              const row = FLAT_DESIGN_CATEGORY.value.find(it => it.value === id);
              return row?.label || '';
            });
            return cates.join('|');
          }).join('、');
          tags.push({
            label: '品类',
            key,
            content: text,
          });
          tagSortList.value.add(key);
          break;
        }
        case SEARCH_SALE_SETTING_ENUM.COMMODITY_STATUS:
          if (commonfuns.isEmpty(value)) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '商品状态',
            key,
            content: $filters.getEnumLabel(COMMODITY_STATUS_OPTIONS.value, value as string),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_SALE_SETTING_ENUM.STORE_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '店铺',
            key,
            content: $filters.getEnumLabel(STORE_NAME_OPTIONS.value, value),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_SALE_SETTING_ENUM.SPU_TOTAL_SALES:
          if (value.length === 2) {
            const [
              sVal,
              eVal,
            ] = value;
            if (commonfuns.isEmpty(sVal) && commonfuns.isEmpty(eVal)) {
              tagSortList.value.delete(key);
              return;
            }
            let txt = '';
            if (commonfuns.isEmpty(sVal) === false && commonfuns.isEmpty(eVal) === false) {
              txt = `${sVal}-${eVal}`;
            } else if (commonfuns.isEmpty(sVal)) {
              txt = `<=${eVal}`;
            } else if (commonfuns.isEmpty(eVal)) {
              txt = `>=${sVal}`;
            }
            tags.push({
              label: '总销量',
              key,
              content: txt,
            });
            tagSortList.value.add(key);
          }
          break;
        case SEARCH_SALE_SETTING_ENUM.SPU_COUPON_PRICE:
          if (value.length === 2) {
            const [
              sVal,
              eVal,
            ] = value;
            if (commonfuns.isEmpty(sVal) && commonfuns.isEmpty(eVal)) {
              tagSortList.value.delete(key);
              return;
            }
            let txt = '';
            if (commonfuns.isEmpty(sVal) === false && commonfuns.isEmpty(eVal) === false) {
              txt = `${sVal}-${eVal}`;
            } else if (commonfuns.isEmpty(sVal)) {
              txt = `<=${eVal}`;
            } else if (commonfuns.isEmpty(eVal)) {
              txt = `>=${sVal}`;
            }
            tags.push({
              label: '价格',
              key,
              content: txt,
            });
            tagSortList.value.add(key);
          }
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.CATEGORY:
        params.value[key] = [];
        break;
      case SEARCH_SALE_SETTING_ENUM.SPU_TOTAL_SALES:
      case SEARCH_SALE_SETTING_ENUM.SPU_COUPON_PRICE:
        params.value[key] = ['', ''];
        break;
      case SEARCH_SALE_SETTING_ENUM.COMMODITY_STATUS:
      case SEARCH_SALE_SETTING_ENUM.STORE_NAME:
        params.value[key] = '';
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    tagSortList.value.clear();
    params.value = cloneDeep(baseParams);
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    baseParams,
    handleChangeSort,
    sortConfig,
    sortList: [...SORT_WORD_LIST],
    params,
    goodsList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
