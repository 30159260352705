
import { defineComponent, PropType } from 'vue';
import { numberfuns } from 'cx-utils';
import { IGoodsItem } from '../../views/type.d';
import { GOODS_STATE_ENUM, GOODS_STATE_LIST } from '@/modules/doupinku-manage/constant';
import usePermissionConfig from '../../use-permission-config';

export default defineComponent({
  emits: ['select-img', 'image', 'search'],
  props: {
    data: {
      type: Object as PropType<IGoodsItem>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const permissionConfig = usePermissionConfig();
    /* eslint-disable no-restricted-globals */
    const intValidator = (num: string | number) => {
      const Nnum = Number(num);
      if (isNaN(Nnum)) return 0;
      if (Nnum < 10000) return Nnum;
      const Fnum = +numberfuns.formatFloat(Nnum / 10000, { pos: 1, round: true });
      return `${Fnum} w`;
    };

    const handleSelectImgItem = (row: IGoodsItem, type: 'quick'|'normal' = 'normal') => {
      const id = localStorage.getItem('quick_design_id');
      emit(
        'select-img',
        {
          id,
          spu: row.spu,
          categoryClass: row?.secondCname || row?.firstCname,
          channelId: row.channelId,
        },
        type,
      );
    };

    const handleImageItem = (row: IGoodsItem) => {
      emit('image', row);
    };

    const handleSearch = (row: IGoodsItem) => {
      emit('search', row);
    };

    return {
      permissionConfig,
      handleImageItem,
      GOODS_STATE_ENUM,
      GOODS_STATE_LIST,
      handleSelectImgItem,
      intValidator,
      handleSearch,
    };
  },
});
