import { computed, ref, Ref } from 'vue';
import { SEARCH_BASE_SETTING_ENUM, SEARCH_SALE_SETTING_ENUM } from '@/modules/doupinku-manage/constant';
import { useDictionaryStore } from '../dictionary';
import { DPK_CUSTOM_DICTIONARY_KEY } from '@/constant/dictionary';
import type{ ISearchConfigItem, ICustomParams } from '../views/type.d';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
) => {
  const dictionaryStore = useDictionaryStore();
  // 品类
  const DESIGN_CATEGORY_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[DPK_CUSTOM_DICTIONARY_KEY.CATEGORY] || []);
  const cascaderConfig = ref({
    multiple: true,
  });
  // 商品状态
  const COMMODITY_STATUS_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[DPK_CUSTOM_DICTIONARY_KEY.COMMODITY_STATUS] || []);
  // 店铺
  const STORE_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[DPK_CUSTOM_DICTIONARY_KEY.STORE_NAME] || []);

  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.CATEGORY,
        render: () => {
          return (
            <custom-cascader
              label="品类"
              options={DESIGN_CATEGORY_OPTIONS.value}
              v-model={params.value.categoryClass}
              show-all-levels={false}
              props={cascaderConfig.value}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.COMMODITY_STATUS,
        render: () => {
          return (
            <virtual-select
              label="商品状态"
              v-model={params.value.commodityStatus}
              options={COMMODITY_STATUS_OPTIONS.value}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.STORE_NAME,
        render: () => {
          return (
            <virtual-select
              label="店铺"
              v-model={params.value.storeName}
              options={STORE_NAME_OPTIONS.value}
            />
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SPU_TOTAL_SALES,
        render: () => {
          return (
            <>
              {params.value.spuTotalSales?.length && (
                <custom-number-range
                  label="总销量"
                  v-models={[
                    [params.value.spuTotalSales[0], 'rangeStart'],
                    [params.value.spuTotalSales[1], 'rangeEnd'],
                  ]}
                  unit="件"
                  min={0}
                  max={999999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s && e && s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
      {
        key: SEARCH_SALE_SETTING_ENUM.SPU_COUPON_PRICE,
        render: () => {
          return (
            <>
              {params.value.spuCouponPrice?.length && (
                <custom-number-range
                  label="价格"
                  v-models={[
                    [params.value.spuCouponPrice[0], 'rangeStart'],
                    [params.value.spuCouponPrice[1], 'rangeEnd'],
                  ]}
                  unit="元"
                  min={0}
                  max={999999999}
                  precision={0}
                  rule={[{
                    validator: (rules: any, value: any, cb: (err?: string) => void) => {
                      if (value?.length) {
                        const [s, e] = value;
                        if (s === '' && e === '') {
                          cb();
                        } else if (s && e && s > e) {
                          cb('最小值不能大于最大值');
                        } else {
                          cb();
                        }
                      } else {
                        cb();
                      }
                    },
                  }]}
                />
              )}
            </>
          );
        },
      },
    ];
    return baseConfig;
  });
  const getOptions = () => {
    dictionaryStore.getCxDictionary([
      DPK_CUSTOM_DICTIONARY_KEY.CATEGORY,
      DPK_CUSTOM_DICTIONARY_KEY.COMMODITY_STATUS,
      DPK_CUSTOM_DICTIONARY_KEY.STORE_NAME,
    ]);
  };
  return {
    searchConfig,
    getOptions,
  };
};
