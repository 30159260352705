
import { defineComponent, ref, nextTick, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { processRouterQueryParams } from '@/utils/';
import useGoodsList from '../composables/use-goods-list';
import { useSearchConfig } from '../composables/use-search-config';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import type {
  IGoodsItem,
} from './type.d';
import type { IDyProductImageSelectionReq } from '@/modules/doupinku-manage/api/type.d';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { SORT_TYPE_ENUM } from '@/modules/doupinku-manage/constant';
import { confirmImage } from '@/modules/doupinku-manage/api';
import { ElMessage } from 'element-plus';
import SelectImgDialog from '../components/select-img-dialog/index.vue';
import Goods from '../components/goods/index.vue';
import { CHANNEL_ENUM } from '@/constant/global';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  components: {
    Goods,
    SelectImgDialog,
  },
  setup() {
    const router = useRouter();
    const goodsEl = ref<null|HTMLElement>(null);

    // 列表查询
    const {
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      handleCurrentSizeChange,
      params,
      goodsList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useGoodsList();

    // 列表查询
    const handleSearch = async () => {
      try {
        reload();
      } catch (error) {
        console.log(error);
      }
    };
    // 字典和搜索项el
    const {
      searchConfig,
      getOptions,
    } = useSearchConfig(params);
    getOptions();

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    // 选图｜快速选图
    const selectImgConfig = ref<{data:IDyProductImageSelectionReq;visible:boolean;}>(
      {
        data: { id: '', spu: '', channelId: '', categoryClass: '' },
        visible: false,
      },
    );
    const handleSelectImgItem = async (param:IDyProductImageSelectionReq, type: 'quick' | 'normal') => {
      const { id = '' } = param;
      let eventDetails: { [key: string]: string; } = {
        eventName: '选图',
        goodId: param.spu || '',
      };
      if (type === 'quick') {
        eventDetails = {
          ...eventDetails,
          eventName: '快速选图',
          taskId: id || '',
        };
      }
      trackingClick(eventDetails);
      if (type === 'quick' && id) {
        await confirmImage(param);
        const row = designTaskOptions.value.find(v => v.id === id);
        if (row) {
          ElMessage.success(`已添加到想法${row.designTaskName}`);
        }
        return;
      }
      selectImgConfig.value = { data: param, visible: true };
    };

    // 点击图片跳转详情
    const handleImageItem = (row: IGoodsItem) => {
      trackingClick({
        eventName: '查看商品详情',
        goodId: row.spu,
      });
      const newPage = router.resolve({
        name: 'DoupinkuDetail',
        query: {
          spu: row.spu,
        },
      });
      window.open(newPage.href);
      // permissionConfig.VIEW_DETAIL.value && router.push({ name: 'DoupinkuDetail', query: { spu: row.spu } });
    };

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      initPagination(goodsEl.value!);
      params.value = urlParams;
    };

    // 找相似
    const handleSearchImage = (row: IGoodsItem) => {
      trackingClick({
        eventName: '找相似',
        goodId: row.spu,
      });
      const newPage = router.resolve({
        name: 'SearchImageList',
        query: {
          url: encodeURIComponent(row.pictureLink),
          channelId: CHANNEL_ENUM.DOUPIN,
        },
      });
      window.open(newPage.href);
    };

    // 选图弹窗-确定
    const handleSelectSuccess = (id: string) => {
      trackingClick({
        eventName: '选图弹窗-点击确定',
        goodId: selectImgConfig.value.data.spu,
        taskId: id,
      });
    };

    onMounted(() => {
      init();
    });

    return {
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      handleSearch,
      handleChangeSort,
      sortList,
      sortConfig,
      searchConfig,
      designTaskOptions,
      handleSelectImgItem,
      selectImgConfig,
      goodsList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      useSearchConfig,
      handleImageItem,
      handleSearchImage,
      handleSelectSuccess,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <filter-bar
            tags={this.sortFiltersTags}
            searchConfig={this.searchConfig}
            onReset={this.handleReset}
            onRemoveItem={this.handleRemoveFilterItem}
          />
          <sort-bar
            sortList={this.sortList}
            ascKey={SORT_TYPE_ENUM.TOP}
            descKey={SORT_TYPE_ENUM.DOWN}
            prop={this.sortConfig.prop}
            order={this.sortConfig.order}
            onHandleItem={this.handleChangeSort}
          />
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.goodsList.map((v, i) => (
                <goods
                  key={i}
                  data={v}
                  onSelectImg={this.handleSelectImgItem}
                  onImage={this.handleImageItem}
                  onSearch={this.handleSearchImage}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgConfig.visible, 'visible']}
          data={this.selectImgConfig.data}
          options={this.designTaskOptions}
          onSuccess={this.handleSelectSuccess}
        />
      </div>
    );
  },
});
