import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';

import { exportByBlob } from '@/core/utils/file-download';
import * as Types from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/* 分割线 */
/**
 * 获取商品品类下拉
 */
export function getCategoryOpts() {
  return http.get<Types.IDyProductCategorySelectRes>({
    url: '/moken-portal/web/v1/dy-product/category-select',
    isCancelDuplicateUrlRequests: true,
    server,
    loading: true,
  });
}

/**
 * 获取普通字典下拉
 */
export function getSelectOptions(params:Types.IDyProductCommonDictSelectionReq) {
  return http.get<Types.IDyProductCommonDictSelectionRes>({
    url: '/moken-portal/web/v1/dy-product/common-dict-selection',
    params,
    isCancelDuplicateUrlRequests: false,
    server,
    loading: true,
  });
}

/**
 * 分页查询抖音商品
 */
export function getDyProductList(data: Types.IDyProductPageReq) {
  return http.post<Types.IDyProductPageRes>({
    url: '/moken-portal/web/v1/dy-product/page',
    // isCancelDuplicateUrlRequests: true,
    server,
    loading: true,
    data,
  });
}

/**
 * 查询商品详情
 */
export function getDyProductListInfo(params: Types.IDyProductFindBySpuReq) {
  return http.get<Types.IDyProductFindBySpuRes>({
    url: '/moken-portal/web/v1/dy-product/find-by-spu',
    isCancelDuplicateUrlRequests: true,
    server,
    loading: true,
    params,
  });
}

/**
 * 确定选图
 */
export function confirmImage(data: Types.IDyProductImageSelectionReq) {
  return http.post<Types.IDyProductImageSelectionRes>({
    url: '/moken-portal/web/v1/dy-product/image-selection',
    loading: true,
    server,
    data,
  });
}

/**
 * 根据商品SPU下载
 */
export const downloadImageBySpu = (params:Types.IDyProductDownloadBySpuReq) => exportByBlob({
  url: '/moken-portal/web/v1/dy-product/download-by-spu',
  loading: true,
  params,
  filename: params.filename,
});

/**
 * 加载更多直播记录
 */
export function loadMoreDyLive(data: Types.IDyProductPageLiveReq) {
  return http.post<Types.IDyProductPageLiveRes>({
    url: '/moken-portal/web/v1/dy-product/page-live',
    loading: true,
    server,
    data,
  });
}
